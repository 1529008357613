/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import styled from '@emotion/styled'
import * as React from 'react'
import ReactModal from 'react-modal'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface Props {
  isOpen: boolean
  image: IGatsbyImageData
  subheading?: string
  close: () => void
}

const ModalClassName = css`
  outline: none;
  height: fit-content;
  /* width: fit-content; */
  display: flex;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  .gatsby-image-wrapper {
    width: 100vw;
    margin: auto;
    /* transition:  */
  }
  @media (min-width: 100vh) {
    .gatsby-image-wrapper {
      min-width: 100vh;
      /* width: auto; */
      height: 100vh;
    }
  }
  @media (min-width: 600px) {
    .gatsby-image-wrapper {
      max-width: 90vw;
      max-height: 80vh;
      min-height: 80vh;
      min-width: 60vw;
      /* width: auto; */
    }
  }
  img {
    object-fit: contain !important;
  }
`

const SubHeading = styled('p')`
  text-align: center;
  font-family: Arvo;
  font-size: 18px;
`

const Modal: React.FC<Props> = ({ isOpen, image, close, subheading }) => (
  <ReactModal isOpen={isOpen} onRequestClose={close} contentLabel="Modal" css={ModalClassName} shouldCloseOnOverlayClick={true}>
    <span onClick={close}>
      <GatsbyImage image={getImage(image) as IGatsbyImageData} alt="fasdf" />
      {subheading && <SubHeading>{subheading}</SubHeading>}
    </span>
  </ReactModal>
)

export default Modal
