/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import * as React from 'react'
import styled from '@emotion/styled'
import ProjectGrid from '../components/ProjectGrid'
import ProjectThumb from '../components/ProjectThumb'
import IndexLayout from '../layouts'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Modal from '../components/Modal'
import { IProjectNode } from '../lib'

const Wrapper = styled('div')`
  padding-top: 17vh;
  .ReactModal__Overlay {
    display: flex;
  }
`
const Title = styled('h1')`
  grid-area: title;
  text-align: center;
  @media (min-width: 500px) {
    padding: 0px 20px;
  }
`
const Description = styled('p')`
  grid-area: desc;
  text-align: center;
  font-weight: 200;
  max-width: 600px;
  margin: 32px auto 0 auto;
  @media (max-width: 600px) {
    padding: 5%;
  }
`

interface State {
  modalIsOpen: boolean
  modalImage: any
}

interface Props {
  data: {
    contentfulProject: IProjectNode
  }
}

class ProjectPage extends React.Component<Props, State> {
  public state = {
    modalIsOpen: false,
    modalImage: {} as IGatsbyImageData
  }

  public openModal = (image: IGatsbyImageData): void => {
    this.setState({ modalIsOpen: true, modalImage: image })
  }

  public closeModal = (): void => {
    this.setState({ modalIsOpen: false })
  }

  public render() {
    const { data } = this.props
    const { modalImage, modalIsOpen } = this.state
    const { title, description, images } = data.contentfulProject
    return (
      <IndexLayout>
        <Wrapper className="outer">
          <Title>{title}</Title>
          <Description>{description.description}</Description>
          <ProjectGrid>
            {images.map((image, idx: number) => (
              <ProjectThumb key={idx} onClick={() => this.openModal(image)}>
                <GatsbyImage
                  image={getImage(image) as IGatsbyImageData}
                  alt="asdfsdfhg"
                  css={css`
                    width: 100%;
                  `}
                />
              </ProjectThumb>
            ))}
          </ProjectGrid>
          <Modal isOpen={modalIsOpen} image={modalImage} close={this.closeModal} subheading={(modalImage as any).title} />
        </Wrapper>
      </IndexLayout>
    )
  }
}

export default ProjectPage
export const query = graphql`
  query galleryQuery($title: String) {
    contentfulProject(title: { eq: $title }) {
      title
      thumbnail {
        gatsbyImageData(
          # width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
      images {
        title
        gatsbyImageData(
          # width: 2000
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
      description {
        description
      }
    }
  }
`
